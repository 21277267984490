import { defineStore } from 'pinia'
import { Ref, ref, useContext } from '@nuxtjs/composition-api'
import { MegaMenuCategoryTree } from '~/modules/mega-menu/interfaces'
import { useConfigStore } from '~/stores/config'
import { SEASON_CATEGORY_NAME } from '~/modules/mega-menu/config/SeasonCategoryName'

const loadingPromises: Record<string, Promise<any>> = {

}

export const useMegaMenuStore = defineStore('mega-menu', () => {
  const { $vsf } = useContext()
  const { storeConfig } = useConfigStore()
  const loading: Ref<boolean> = ref(true)
  const preparedForFirstLevel = ref<boolean>(false)
  const categories = ref<MegaMenuCategoryTree[]>([])

  async function load (onlyFirstLevel: boolean = false): Promise<void> {
    if (categories.value.length) {
      if (onlyFirstLevel || !preparedForFirstLevel.value) {
        return
      }
    }

    if (loadingPromises[onlyFirstLevel.toString()] !== undefined) {
      return await loadingPromises[onlyFirstLevel.toString()]
    }

    await (loadingPromises[onlyFirstLevel.toString()] = loadWrapper(onlyFirstLevel))
    loadingPromises[onlyFirstLevel.toString()] = undefined
  }

  async function loadWrapper (onlyFirstLevel: boolean = false): Promise<void> {
    loading.value = true
    const { data } = await $vsf.$magento.api.categoryList({}, {
      categoryList: 'menuCategories'
    })

    categories.value = data.categories.items as MegaMenuCategoryTree[]
    if (!categories.value.length) {
      // eslint-disable-next-line no-console
      console.warn('menuCategories query returned empty result')
    }

    categories.value[0].children = buildRootCategories(categories.value[0].children, onlyFirstLevel)
    prepareMenuCategories()
    preparedForFirstLevel.value = onlyFirstLevel
    loading.value = false
  }

  function buildRootCategories (categories: MegaMenuCategoryTree[], onlyFirstLevel: boolean = false): MegaMenuCategoryTree[] {
    const rootCategories: MegaMenuCategoryTree[] = []

    for (const source of categories) {
      source.childrenCount = source.children?.length || 0
      if (source?.include_in_root_menu && source.level >= 2) {
        rootCategories.push(source)
      }

      if (source?.children?.length) {
        rootCategories.push(...buildRootCategories(source.children, onlyFirstLevel))
      }

      if (onlyFirstLevel) {
        delete source.children
      }
    }

    return rootCategories
      .sort((x, y) => x.position_in_root_menu - y.position_in_root_menu)
  }

  /**
   * Find seasons categories. If 2 or more seasons then combine all categories in one parent category.
   * If 1 then rename season category
   */
  function prepareMenuCategories (): void {
    const seasonsConfigCategories = storeConfig.season_goods_categories ? storeConfig.season_goods_categories.split(',') : []
    if (seasonsConfigCategories.length) {
      const seasonsCategories = categories.value[0].children.filter((category) => {
        return seasonsConfigCategories.includes(category.uid) && !!category.product_count
      })

      if (seasonsCategories.length > 1) {
        categories.value[0].children = categories.value[0].children.filter((category) => {
          return !seasonsCategories.find(c => c.uid === category.uid)
        })

        categories.value[0].children.push({
          id: Date.now(),
          include_in_menu: 1,
          include_in_root_menu: true,
          is_anchor: 1,
          level: 2,
          name: SEASON_CATEGORY_NAME,
          position: seasonsCategories[0].position,
          position_in_root_menu: seasonsCategories[0].include_in_root_menu,
          product_count: seasonsCategories.reduce((count, category) => {
            return count + category.product_count
          }, 0),
          uid: SEASON_CATEGORY_NAME,
          url_path: undefined,
          url_suffix: undefined,
          image: seasonsCategories.find(category => !!category?.image)?.image ?? undefined,
          children: seasonsCategories
        } as any)
        return
      }

      categories.value[0].children
        .forEach((category) => {
          if (seasonsConfigCategories.includes(category.uid)) {
            category.name = SEASON_CATEGORY_NAME
          }
        })
    }
  }

  return {
    categories,
    preparedForFirstLevel,
    loading,
    load
  }
})
